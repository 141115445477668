import MovLink from "./movlink";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import PropTypes from "prop-types";
import React from "react";
import { Location } from "@reach/router"; // to get the location
import Headroom from "react-headroom"; // https://kyleamathews.github.io/react-headroom/
import { css } from "@emotion/core"; // https://github.com/gatsbyjs/gatsby/blob/master/examples/using-emotion/src/pages/index.js
import { Flex, Box } from "@rebass/grid/emotion"; //https://github.com/rebassjs/grid
import {
  Accent,
  HeaderOffset,
  HeaderOffsetMobile,
  MainWrapper,
  SubtleAccent,
} from "../utils/styles";

let menuButtonVal;
//

const headerScaler = 45;
const headerHeight = css`
  transition: all 0.3s;
  height: ${HeaderOffsetMobile + "px"};
  @media (min-width: 40em) {
    height: ${HeaderOffset + "px"};
  }
`;

const homeTitle = css`
  transition: all 0.3s;
  /* float: left; */
  font-weight: 300;
  margin: 0;
  padding-left: 0.8rem;
  padding-top: 0.5rem;
  font-size: 2em;
  @media (min-width: 40em) {
    padding-left: 0;
    padding-top: 1.4rem;
  }
  a {
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
    color: white;
  }
  span {
    padding: 0 2px 0 2px;
    font-weight: 400;
    position: relative;
    @media (min-width: 40em) {
      padding: 0 5px 0 6px;
    }
  }
`;

const navBar = css`
  transition: all 0.3s;
  /* float: right; */
  padding-top: 2rem;
  padding-left: 0.8rem;
  padding-bottom: 0.1rem;
  /* display: none; */
  @media (min-width: 40em) {
    /* display: block; */
    padding-top: 1.93rem;
    padding-bottom: 0.3rem;
  }

  input[type="checkbox"]:checked ~ #sidebarMenu {
    transform: translateX(0);
  }

  input[type="checkbox"] {
    transition: all 0.3s;
    box-sizing: border-box;
    display: none;
  }
  .sidebarIconToggle {
    display: block;
    transition: all 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    position: absolute;
    z-index: 99;
    /* height: 100%;
    width: 100%; */
    top: 7px;
    right: 14px;
    height: ${headerScaler}px;
    width: ${headerScaler}px;
    /* padding-top: 0.2rem; */
    padding: 10px 10px;
    @media (min-width: 40em) {
      padding: 20px 10px;
      width: ${headerScaler * 1.2}px;
      height: ${headerScaler * 1.4}px;
      top: 5px;
      right: 23px;
    }
    @media (min-width: 52em) {
      display: none;
    }
    span {
      display: none;
    }
  }
  .spinner {
    transition: all 0.3s;
    box-sizing: border-box;
    position: absolute;
    height: 2px;
    @media (min-width: 40em) {
      height: 2px;
    }
    width: 100%;
    /* background-color: black; */
  }
  .sidebarIconToggle:hover {
    .spinner {
      background-color: ${Accent};
    }
  }
  .sidebarIconToggle:active {
    .spinner {
      background-color: ${Accent};
    }
  }
  .horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 6px;
    @media (min-width: 40em) {
      margin-top: 6px;
    }
  }
  .diagonal.part-1 {
    position: relative;
    transition: all 0.3s;
    box-sizing: border-box;
    float: left;
  }
  .diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-top: 6px;
    @media (min-width: 40em) {
      margin-top: 6px;
    }
  }
  input[type="checkbox"]:checked ~ .sidebarIconToggle > .horizontal {
    transition: all 0.3s;
    box-sizing: border-box;
    opacity: 0;
    /* background: ${menuButtonVal}; */
  }
  input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(135deg);
    margin-top: 8px;
    /* background: ${menuButtonVal}; */
  }
  input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
    transition: all 0.3s;
    box-sizing: border-box;
    transform: rotate(-135deg);
    margin-top: -9px;
    /* background: ${menuButtonVal}; */
  }
  #sidebarMenu {
    display: block;
    @media (min-width: 52em) {
      display: none;
    }
    /* height: 100%; */
    position: fixed;
    /* z-index: 1000; */
    left: 0;
    top: 0;
    width: 100vw;
    min-height: 100vh;

    transform: translateX(120vw);
    transition: transform 200ms ease-in-out;
    /* background: linear-gradient(180deg, #fff 0%, #ccc 100%); */
    background: white;
  }

  .sidebarMenuInner {
    margin: 25px 0 0 0;
    @media (min-width: 40em) {
      margin: 10px 0 0 0;
    }
    padding: 0;
    /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
  }
  .sidebarMenuInner li {
    list-style: none;
    color: #fff;
    /* text-transform: uppercase; */
    /* font-weight: 300; */
    /* padding: 20px; */
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    text-align: center;
  }
  .sidebarMenuInner li span {
    display: block;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
  }
  .sidebarMenuInner li a {
    color: black;
    cursor: pointer;
    text-decoration: none;
    font-size: 200%;
  }
`;

// const sideBarMenu = css``;

const menuList = css`
  display: none;
  @media (min-width: 52em) {
    display: block;
  }
  /* float: right; */
  /* display: inline; */
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

const menuListItem = css`
  /* float: left; */
  display: inline;
  margin: 0;
  a {
    /* color: black; */
    /* text-align: center; */
    /* padding: 16px; */
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  a:active {
    color: ${Accent};
  }
  font-size: 114%;
  padding: 0.6rem 0.5rem 0.5rem 0rem;
  @media (min-width: 40em) {
    font-size: 120%;
    padding: 1rem 0 0rem 1.5rem;
  }
`;

const sidebarListItem = css`
  font-size: 80%;
  font-weight: 300;
  text-transform: uppercase;
  /* text-decoration: underline; */
  a:hover > div {
    color: white;
    background: ${Accent};
  }
  a:active > div {
    color: white;
    background: ${Accent};
  }
`;

const sidebarlink = css`
  /* background: green; */
  padding: 20px 0;
  @media (min-width: 40em) {
    padding: 5px 0;
  }
  color: ${SubtleAccent};
`;

const Header = class extends React.Component {
  // const Header = ({ siteTitle }) => (

  constructor(props) {
    super(props);
    if (this.props.location === "home") {
      this.state = {
        // active: false,
        // navBarActiveClass: ""
        headerMobileMarginTop: 0,
        headerMarginTop: 0,
        headerMobilePaddingTop: HeaderOffsetMobile + "px",
        headerPaddingTop: HeaderOffset + "px",
        headroomBackground: "transparent",
        menubg: "white",
        menuSlash: "white",
        menuButton: "black",
      };
    } else {
      this.state = {
        // active: false,
        // navBarActiveClass: ""
        headerMobileMarginTop: HeaderOffsetMobile + "px",
        headerMarginTop: HeaderOffset + "px",
        headerMobilePaddingTop: 0,
        headerPaddingTop: 0,
        headroomBackground: SubtleAccent,
        menubg: "white",
        menuSlash: "white",
        menuButton: "white",
      };
    }
  }

  componentDidMount() {
    if (this.props.location === "home") {
      window.addEventListener("scroll", this.listenToScroll);
    }
  }

  componentWillUnmount() {
    if (this.props.location === "home") {
      window.removeEventListener("scroll", this.listenToScroll);
    }
  }

  listenToScroll = () => {
    if (this.props.location === "home") {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;

      // const height =
      //   document.documentElement.scrollHeight -
      //   document.documentElement.clientHeight;

      //height of whole window
      const windowHeight = document.documentElement.clientHeight;
      //a bit more than height of whole window + inital window height
      // const scrollHeight = document.documentElement.scrollHeight;

      // const scrolled = winScroll / height;
      if (winScroll > windowHeight || this.refs.sidebarToggle.checked) {
        this.setState({
          headroomBackground: SubtleAccent,
          menubg: "white",
        });
      } else {
        this.setState({
          headroomBackground: "transparent",
          menubg: "white",
        });
      }
    }
  };

  unCheck(ref) {
    this.refs[ref].checked = !this.refs[ref].checked;
  }

  checkMenu(ref) {
    if (ref) {
      if (this.refs.sidebarToggle.checked) {
        this.refs[ref].checked = !this.refs[ref].checked;
      }
    }

    if (this.refs.sidebarToggle.checked && this.props.location === "home") {
      this.setState({
        menubg: "black",
        menuSlash: "white",
      });
    } else if (this.props.location === "home") {
      this.setState({
        menubg: "white",
        menuSlash: "white",
      });
    }
  }

  render() {
    // listenToScroll()
    // let currentLocation;

    menuButtonVal = this.state.menuButton;

    return (
      <>
        <Location>
          {({ location }) => {
            // currentLocation = location.pathname;
            // return <p>Current Location: {location.pathname}</p>;
            // if(currentLocation === "/"){
            //   this.setState({
            //     headroomBackground: "transparent",
            //     menubg: "white"
            //   });
            // }
          }}
        </Location>
        <Headroom
          // wrapperStyle={{ marginBottom: rhythm(1) }}
          style={{
            background: this.state.headroomBackground,
            transition: "all 0.5s",
            // boxShadow: "1px 1px 15px rgba(0,0,0,0.1)",
            // borderBottom: "1px solid grey",
            zIndex: 100000,
          }}
          // style={{ display: this.state.isDisplayNavbar }}
          // onPin={() => this.setState({ headroomBackground: "SubtleAccent" })}
        >
          {/* {console.log("location",this.props.location)} */}
          <header css={headerHeight}>
            <MainWrapper>
              <Flex flexWrap="wrap">
                <Box
                  width={[1, 2 / 6]}
                  css={css`
                    z-index: 100;
                    width: auto;
                    overflow: hidden;
                  `}
                >
                  {/* Half width */}
                  <h1
                    css={homeTitle}
                    onClick={() => this.checkMenu("sidebarToggle")}
                  >
                    <AniLink
                      fade
                      duration={0.6}
                      to={"/"}
                      activeStyle={{ color: this.state.menubg }}
                      css={css`
                        color: ${this.state.menubg};
                      `}
                    >
                      MA
                      <span
                        css={css`
                          color: ${this.state.menuSlash};
                        `}
                      >
                        /
                      </span>
                      UX
                    </AniLink>
                  </h1>
                </Box>
                <Box
                  width={[1, 4 / 6]}
                  css={css`
                    /* z-index: 101; */
                    text-align: left;
                    @media (min-width: 40em) {
                      text-align: right;
                    }
                  `}
                >
                  {/* Half width */}
                  <nav css={navBar}>
                    <ul
                      css={[
                        menuList,
                        css`
                          a {
                            color: ${this.state.menubg};
                          }
                        `,
                      ]}
                    >
                      <li css={menuListItem}>
                        <MovLink to="/projects">Projects</MovLink>
                      </li>
                      <li css={menuListItem}>
                        <MovLink to="/students">Students</MovLink>
                      </li>
                      <li css={menuListItem}>
                        <MovLink to="/competencies">Competencies</MovLink>
                      </li>
                      <li
                        css={[
                          menuListItem,
                          css`
                            a {
                              color: ${this.props.location == "showpage"
                                ? "gold"
                                : "white"};
                            }
                          `,
                        ]}
                      >
                        <MovLink to="/2023">Show 2023</MovLink>
                      </li>
                      <li css={menuListItem}>
                        <MovLink to="/about">About</MovLink>
                      </li>
                    </ul>

                    <input
                      type="checkbox"
                      className="openSidebarMenu"
                      id="openSidebarMenu"
                      ref={"sidebarToggle"}
                      onClick={() => this.checkMenu()}
                    />
                    <label
                      htmlFor="openSidebarMenu"
                      className="sidebarIconToggle"
                    >
                      <span>x</span>
                      <div
                        className="spinner diagonal part-1"
                        css={css`
                          background-color: ${this.state.menubg};
                        `}
                      />
                      <div
                        className="spinner horizontal"
                        css={css`
                          background-color: ${this.state.menubg};
                        `}
                      />
                      <div
                        className="spinner diagonal part-2"
                        css={css`
                          background-color: ${this.state.menubg};
                        `}
                      />
                    </label>
                    <div
                      id="sidebarMenu"
                      css={css`
                        margin-top: ${this.state.headerMobileMarginTop};
                        @media (min-width: 40em) {
                          margin-top: ${this.state.headerMarginTop};
                        }
                        padding-top: ${this.state.headerMobilePaddingTop};
                        @media (min-width: 40em) {
                          padding-top: ${this.state.headerPaddingTop};
                        }
                      `}
                    >
                      <ul className="sidebarMenuInner">
                        {/* <li
                          css={sidebarListItem}
                          onClick={() => this.checkMenu("sidebarToggle")}
                        >
                          <MovLink
                            to="/"
                            css={css`
                              color: black;
                              text-decoration: none;
                            `}
                          >
                            <div css={sidebarlink}>Home</div>
                          </MovLink>
                        </li> */}
                        <li
                          css={sidebarListItem}
                          onClick={() => this.unCheck("sidebarToggle")}
                        >
                          <MovLink
                            to="/projects"
                            css={css`
                              color: black;
                              text-decoration: none;
                            `}
                          >
                            <div css={sidebarlink}>Projects</div>
                          </MovLink>
                        </li>
                        <li
                          css={sidebarListItem}
                          onClick={() => this.unCheck("sidebarToggle")}
                        >
                          <MovLink
                            to="/students"
                            css={css`
                              color: black;
                              text-decoration: none;
                            `}
                          >
                            <div css={sidebarlink}>Students</div>
                          </MovLink>
                        </li>
                        <li
                          css={sidebarListItem}
                          onClick={() => this.unCheck("sidebarToggle")}
                        >
                          <MovLink
                            to="/competencies"
                            css={css`
                              color: black;
                              text-decoration: none;
                            `}
                          >
                            <div css={sidebarlink}>Competencies</div>
                          </MovLink>
                        </li>
                        <li
                          css={sidebarListItem}
                          onClick={() => this.unCheck("sidebarToggle")}
                        >
                          <MovLink
                            to="/2022"
                            css={css`
                              color: black;
                              text-decoration: none;
                            `}
                          >
                            <div css={sidebarlink}>Show 2022</div>
                          </MovLink>
                        </li>
                        <li
                          css={sidebarListItem}
                          onClick={() => this.unCheck("sidebarToggle")}
                        >
                          <MovLink
                            to="/about"
                            css={css`
                              color: black;
                              text-decoration: none;
                            `}
                          >
                            <div css={sidebarlink}>About</div>
                          </MovLink>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </Box>
              </Flex>
            </MainWrapper>
          </header>
        </Headroom>
      </>
    );
  }
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
